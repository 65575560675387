import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Environment, ENVIRONMENT_TOKEN } from '@lms/shared/models';
import { catchError, filter, Observable, of, take } from 'rxjs';

const VERSION_POLL_INTERVAL = 600000;

@Injectable({ providedIn: 'root' })
export class AppVersionInterceptor {
  currentUrl = '';
  previousUrl = '';
  shouldRefreshPage = false;

  previousVersion: string = '' + this.environment.appVersion;
  currentVersion!: string;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) public environment: Environment,
    private http: HttpClient,
    private router: Router,
  ) {}

  getVersion(): Observable<any> {
    const salt = new Date().getTime();

    const headers = new HttpHeaders({
      'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      Pragma: 'no-cache',
      Expires: '0',
    });
    return this.http
      .get<any>(`/version?t=${salt}`, {
        headers: headers,
      })
      .pipe(take(1));
  }

  init(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });

    this.initializeAppVersionSync();
    setInterval(() => {
      this.initializeAppVersionSync();
    }, VERSION_POLL_INTERVAL);
  }

  private initializeAppVersionSync(): void {
    if (!this.environment.appVersionSync) {
      return;
    }

    this.getVersion()
      .pipe(
        catchError((err: any) => {
          return of(err.error.text);
        }),
      )
      .subscribe(version => {
        this.currentVersion = '' + version;
        if (this.currentVersion && this.previousVersion && this.previousVersion !== this.currentVersion?.trim()) {
          this.shouldRefreshPage = true;
        }
      });
  }
}
