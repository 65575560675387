import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CurrentUserStorage, ParameterStorage } from '@lms/shared/storages';
import { Observable, tap } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(
    private currentUserStorage: CurrentUserStorage,
    private parameterStorage: ParameterStorage,
    private cookies: CookieService,
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.url.startsWith('/version')) {
      return next.handle(req);
    }

    let headers = new HttpHeaders();
    headers = headers.set('X-PARAMETERS-CHECKSUM', this.parameterStorage.getChecksum());
    headers = headers.set('X-CALLER', window.location.href);
    const currentUser = this.currentUserStorage.getCurrentUser();
    if (currentUser) {
      headers = headers.set('X-CURRENT-USER-CHECKSUM', currentUser.checksum);
    }

    const authReq = req.clone({
      headers,
      responseType: 'json',
      withCredentials: true,
    });

    return next.handle(authReq).pipe(
      tap((response: any) => {
        // refresh current user info if data changed
        if (response?.body?.data?.currentUser) {
          this.currentUserStorage.setCurrentUser(response.body.data.currentUser);
        }
        if (response?.body?.data?.frontendParameters && response?.body?.data?.frontendParametersChecksum) {
          this.parameterStorage.setParameters(response.body.data.frontendParameters, response.body.data.frontendParametersChecksum);
        }
      }),
    );
  }
}
